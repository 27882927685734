/*Homeoage Hero Banner Starts*/
.banner-wrap {
  .owl-item {
    line-height: 0;
  }
  .banner-item {
    position: relative;
    .banner-item {
      width: 100%;
    }
    .banner-content {
      max-width: 464px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 5%;
      padding: 22px 32px 32px;
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(16px);
      p {
        padding-top: 12px;
        padding-bottom: 26px;
      }
      .fill-btn {
        padding: 0 34px;
      }
    }
  }
  .slider-item {
    .owl-dots {
      margin-top: 0 !important;
      position: absolute;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
      @include ultra-mobile {
        bottom: 16px;
      }
      .owl-dot {
        span {
          margin: 0;
          @include hw(12px);
          background-color: $line;
          @include ultra-mobile {
            @include hw(8px);
          }
          &:hover {
            background-color: $secondary;
          }
        }
        &:not(:last-child) {
          span {
            margin-right: 16px;
          }
        }
        &.active {
          span {
            background-color: $secondary;
            &:hover {
              background-color: $secondary;
            }
          }
        }
      }
    }
  }
}

/*Skeleton Homeoage Hero Banner Starts*/
.slider-item-skl {
  min-height: 632px;
  height: 100%;
  display: block;
  @include desktop {
    min-height: 400px;
  }
  @include tablet {
    min-height: 300px;
  }
  @include ultra-mobile {
    min-height: 240px;
  }
  span {
    min-height: inherit;
    height: inherit;
  }
}
/*Skeleton Homeoage Hero Banner Ends*/
/*Homeoage Hero Banner Ends*/

/*Selling Point Starts*/

.selling-point-wrap {
  background-color: $secondary;
  padding: 8px 0 0 0;
  @include ultra-mobile {
    padding: 8px 0 0 0;
  }
  .point-list {
    @include flex-start-center;
    max-width: 888px;
    margin: 0 auto;
    @include prefix(flex-wrap, wrap);
    @include ultra-mobile {
      @include flex-start-center;
    }
    .sub-point {
      width: calc(100% / 3);
      padding-bottom: 8px;
      @include ultra-mobile {
        padding-bottom: 16px;
      }
      .point-links {
        @include flex-start-center;
        @include ultra-mobile-above {
          white-space: nowrap;
        }
        img {
          width: 48px;
          height: 48px;
        }
        span {
          color: $white;
          @include ultra-mobile-above {
            margin-left: 16px;
          }
        }
        @include ultra-mobile {
          @include prefix(flex-direction, column);
          text-align: center;
        }
      }
      @include ultra-mobile-above {
        // &:not(:first-child) {
        //   margin-left: 48px;
        // }
      }
    }
  }
}

/*Point Skeleton Starts*/
.point-list.skl {
  .sub-point-skl {
    display: flex;
    width: calc(100% / 3);
    padding-bottom: 8px;
    align-items: center;
    @include ultra-mobile {
      @include prefix(flex-direction, column);
    }
    .for-text-skl {
      margin-left: 8px;
      @include ultra-mobile {
        margin-left: 0;
      }
    }
    span {
      &:nth-child(2) {
        width: calc(100% - 120px);
        @include ultra-mobile {
          width: 80%;
        }
      }
    }
  }
}
/*Point Skeleton Ends*/
/*Selling Point Ends*/

/*Category Block Starts*/

.categoryblock-wrap {
  padding-top: 24px;
  @include ultra-mobile {
    padding-top: 16px;
  }
  @include ultra-mobile {
    .custom-container {
      padding-right: 0;
    }
  }
  .category-list {
    @include ultra-mobile-above {
      @include flex-between-start;
    }
    @include ultra-mobile {
      overflow-x: scroll;
      white-space: nowrap;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .item {
      @include ultra-mobile-above {
        width: calc(calc(100% / 3) - 16px);
      }
      @include ultra-mobile {
        display: inline-block;
        width: 85%;
        margin-right: 16px;
        &:last-child {
          margin-right: 12px;
        }
        a {
          width: 100%;
        }
      }
      .cat-name {
        padding-top: 8px;
      }
    }
  }
}

/*Skeleton Category Block Starts*/
.category-list{
  
  .skl-img-section {
    min-height: 400px;
    height: 100%;
    @include desktop {
      min-height: 280px;
    }
    @include tablet {
      min-height: 200px;
    }
    @include ultra-mobile {
      min-height: 280px;
    }
    span {
      min-height: inherit;
      height: inherit;
    }
  }
}

/*Skeleton Category Block Ends*/
/*Category Block Ends*/

/*Card Banner Starts*/
.cardbanner-wrap {
  padding-top: 24px;
  @include ultra-mobile {
    // padding-top: 60px;
  }
  .cardrel-content {
    @include flex-between-start;
    @include tablet {
      @include prefix(flex-direction, column);
    }
    a {
      width: calc(50% - 12px);
      @include tablet {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
    .inner-card {
      .cr-desc {
        text-align: center;
        background-color: $cardbg;
        padding: 32px;
        @include ultra-mobile {
          padding: 24px 8px;
        }
        h6 {
          margin-bottom: 14px;
        }
        p {
          margin-bottom: 18px;
          display: block;
          display: -webkit-box;
          max-width: 100%;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @include mobile {
            -webkit-line-clamp: 3;
            margin-bottom: 19px;
          }
          @include ultra-mobile {
            @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
          }
        }
        @include tablet-above {
          .fill-btn {
            width: 296px;
            padding: 0 20px;
          }
        }
        @include mini-iphone-mobile {
          .fill-btn {
            padding: 0 12px;
          }
        }
      }
    }
  }
}

/*Card Banner Skeleton Starts*/
.inner-card.skl-content {
  width: calc(50% - 12px);
  @include tablet {
    width: 100%;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  .cr-image {
    min-height: 648px;
    height: 100%;
    display: block;
    @include desktop {
      min-height: 520px;
    }
    @include tablet {
      min-height: 700px;
    }
    @include ultra-mobile {
      min-height: 560px;
    }
    span {
      min-height: inherit;
      height: inherit;
    }
  }
  .desc-skl {
    padding: 32px;
    background-color: $cardbg;
  }
}
/*Card Banner Skeleton Ends*/
/*Card Banner Ends*/

/*Product Spotlight Starts*/
.product-spotlight-wrap {
  padding-top: 24px;
  padding-bottom: 20px;
  .custom-container {
    @include ultra-mobile {
      padding-right: 0;
    }
  }
  .section-title {
    text-align: center;
    margin-bottom: 24px;
  }
  .product-slider {
    .list-item {
      position: relative;
      .product-details {
        .price-wrapper {
          padding-top: 4px;
        }
      }
    }
    .owl-nav {
      margin-top: 0;
      width: auto;
      position: absolute;
      right: 0;
      top: -56px;
      @include ultra-mobile {
        display: none;
      }
      .owl-prev,
      .owl-next {
        background: url("../../Assets/Images/slider-arroleft.svg") center no-repeat;
        margin: 0;
        @include hw(32px);
        padding: 4px !important;
        border: 1px solid $arrowhover;
        margin-left: 16px;
        @include prefix(border-radius, 4px);
        &:hover {
          border-color: $primary;
        }
        span {
          display: none;
        }
      }
      .owl-next {
        background: url("../../Assets/Images/slider-arroright.svg") center no-repeat;
      }
    }
    .heart-select {
      display: inline-block;
      position: absolute;
      top: 8px;
      right: 8px;
      transition-duration: 0.15s;
      transition-timing-function: ease-in-out;
      input {
        @include hw(24px);
        display: inline-block;
        cursor: pointer;
        position: relative;
        transition-duration: 0.15s;
        transition-timing-function: ease-in-out;
        background: url("../../Assets/Images/heart-favorites.svg");
        background-position: center;
        background-size: cover;
        &:checked {
          background: url("../../Assets/Images/heart-favorites-fill.svg");
        }
        &:checked:hover {
          background: url("../../Assets/Images/heart-favorites-fill.svg");
        }
        &:hover {
          background: url("../../Assets/Images/heart-favorites-hover.svg");
        }
      }
    }
  }
}

/*Product Slider Skeleton Starts*/
.skl-spot {
  max-width: 25%;
  margin: 0 auto 24px auto;
  @include ultra-mobile {
    max-width: 50%;
  }
}
.skl-spot-products {
  .list-item {
    .img-skl {
      min-height: 312px;
      height: 100%;
      display: block;
      @include desktop {
        min-height: 200px;
      }
      @include tablet {
        min-height: 160px;
      }
      @include ultra-mobile {
        min-height: 210px;
      }
      span {
        min-height: inherit;
        height: inherit;
      }
    }
    .name-skl {
      max-width: 50%;
    }
    .price-skl {
      max-width: 35%;
    }
  }
}
/*Product Slider Skeleton Ends*/
/*Product Spotlight Ends*/

/*Testimonial Starts*/
.testimonial-wrap {
  background-color: $cardbg;
  padding: 64px 0 52px 0;
  @include ultra-mobile {
    padding: 24px 0 20px 0;
    h6 {
      text-align: center;
      margin-bottom: 24px;
    }
    .custom-container {
      padding-right: 0;
    }
  }
  .custom-container {
    max-width: 1238px;
  }
  .testmo-sections {
    position: relative;
    .action-bar {
      position: absolute;
      bottom: 8px;
      left: 0;
      z-index: 9999;
      @include tablet {
        display: none;
      }
    }
  }
  .testimo-slides {
    @include flex-start-start;
    @include ultra-mobile {
      @include prefix(flex-direction, column-reverse);
    }
    .testmo-left {
      width: calc(100% - 584px);
      padding-right: 88px;
      padding-top: 8px;
      @include custom-tablet {
        width: 40%;
        padding-right: 24px;
      }
      @include ultra-mobile {
        width: 100%;
        padding-right: 0;
      }
      .tm-desc {
        padding-top: 22px;
        @include custom-tablet {
          padding-top: 8px;
          display: block;
          display: -webkit-box;
          max-width: 100%;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @include ultra-mobile {
          padding-top: 12px;
          @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
        }
      }
      .tm-name {
        margin-top: 20px;
        color: $mid;
        @include custom-tablet {
          margin-top: 4px;
        }
        @include ultra-mobile {
          margin-top: 16px;
          @include fonts($InterFont, $mid, 14px, 400, 20px, 0.25px);
        }
      }
     
    }
    .testmo-right {
      @include custom-tablet {
        width: 80%;
        a {
          width: 100%;
        }
      }
      @include ultra-mobile {
        width: 100%;
      }
      p {
        padding-top: 8px;
      }
    }
  }
  .owl-carousel {
    .owl-nav {
      margin-top: 0;
      position: absolute;
      left: 0;
      bottom: 92px;
      @include custom-tablet {
        bottom: 4px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        bottom: 72px;
      }
      @include ultra-mobile {
        display: none;
      }
      .owl-prev,
      .owl-next {
        background: url("../../Assets/Images/slider-arroleft.svg") center no-repeat;
        margin: 0;
        @include hw(32px);
        padding: 4px !important;
        border: 1px solid $line;
        @include prefix(border-radius, 4px);
        background-color: $surface;
        &:hover {
          border-color: $primary;
        }
        span {
          display: none;
        }
      }
      .owl-next {
        background: url("../../Assets/Images/slider-arroright.svg") center no-repeat;
        margin-left: 16px;
        background-color: $surface;
      }
    }
  }
}

/*Skeleton Testimonial Starts*/
.skl-tesmo {
  padding-right: 12px;
  .testmo-left {
    width: calc(100% - 584px);
    @include custom-tablet {
      width: 40%;
    }
    .skl-title {
      max-width: 50%;
    }
    .skl-desc {
      margin-top: 20px;
      min-height: 80px;
      height: 100%;
      display: block;
      @include tablet {
        min-height: 160px;
      }
      @include ultra-mobile {
        margin-top: 8px;
        min-height: 140px;
      }
      @include iphone {
        margin-top: 8px;
        min-height: 120px;
      }
      span {
        min-height: inherit;
        height: inherit;
      }
    }
    .skl-cname {
      max-width: 65%;
      margin-top: 32px;
      @include ultra-mobile {
        margin-top: 16px;
      }
    }
    .skl-btn{
      margin-top: 36px;
      height: 100%;
      display: block;
      height: 40px;
      width: 140px;
      span {
        min-height: inherit;
        height: inherit;
      }
    }
  }
  .testmo-right {
    width: 584px;
    @include custom-tablet {
      width: 80%;
    }
    .skl-pname {
      width: 50%;
    }
    .skl-image {
      min-height: 300px;
      height: 100%;
      display: block;
      @include tablet {
        min-height: 260px;
      }
      @include ultra-mobile {
        min-height: 190px;
      }
      @include iphone {
        min-height: 140px;
      }
      span {
        min-height: inherit;
        height: inherit;
      }
    }
  }
}
/*Skeleton Testimonial Ends*/
/*Testimonial Ends*/

/*Homepage About Us Starts*/
.aboutus-wrap {
  padding-top: 32px;
  padding-bottom: 8px;
  @include tablet {
    padding-top: 24px;
    padding-bottom: 16px;
    h6 {
      text-align: center;
      margin-bottom: 24px;
    }
  }
  .abtus-sections {
    @include flex-start-start;
    @include tablet {
      @include prefix(flex-direction, column);
    }
    .abt-left {
      max-width: 624px;
      position: relative;
      @include tablet {
        max-width: 100%;
        width: 100%;
      }
      .trust-review {
        position: absolute;
        left: 32px;
        bottom: 32px;
        @include ultra-mobile {
          top: auto;
          bottom: 16px;
          left: 16px;
          transform: none;
        }
      }
    }
    .abt-right {
      width: calc(100% - 624px);
      padding-left: 72px;
      @include desktop {
        padding-left: 24px;
        width: calc(100% - 500px);
      }
      @include tablet {
        padding-left: 0;
        width: 100%;
      }
      .right-top {
        @include tablet {
          padding-top: 16px;
        }
        @include ultra-mobile {
          padding-top: 16px;
        }
        h6 {
          margin-top: 8px;
          margin-bottom: 16px;
          @include desktop {
            margin-bottom: 8px;
            margin-top: 0;
          }
          @include tablet {
            margin-top: 8px;
            margin-bottom: 16px;
          }
        }
        p {
          @include ultra-mobile {
            @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
          }
        }
      }
      .right-points {
        padding-top: 40px;
        padding-bottom: 36px;
        @include desktop {
          padding-bottom: 16px;
          padding-top: 20px;
        }
        @include tablet {
          padding-bottom: 24px;
          padding-top: 20px;
        }
        @include ultra-mobile {
          padding-bottom: 24px;
          padding-top: 16px;
        }
        li {
          @include flex-start-center;
          &:before {
            content: " ";
            background-color: $secondary;
            @include hw(12px);
            @include prefix(border-radius, 50%);
            margin-right: 12px;
          }
          &:not(:last-child) {
            margin-bottom: 12px;
          }
          @include ultra-mobile {
            @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
          }
        }
      }
      .right-action {
        .outline-btn {
          @include flex-center-center;
          max-width: 160px;
        }
      }
    }
  }
}

/*Skeleton About Us Starts*/
.aboutus-wrap {
  .abtus-sections.skl {
    .abt-left.skl {
      min-height: 300px;
      height: 100%;
      display: block;
      width: 100%;
      max-width: 624px;
      @include desktop {
        min-height: 400px;
      }
      @include tablet {
        max-width: 100%;
      }
      span {
        min-height: inherit;
        height: inherit;
      }
    }
    .abt-right.skl {
      .skl-title {
        margin-bottom: 16px;
        height: 100%;
        display: block;
        width: 45%;
        @include tablet {
          display: none;
        }
        span {
          min-height: inherit;
          height: inherit;
        }
      }
      .skl-desc {
        margin-bottom: 24px;
        height: 100%;
        display: block;
        height: 120px;
        @include tablet {
          margin-top: 24px;
        }
        span {
          min-height: inherit;
          height: inherit;
        }
      }
      .skl-points {
        width: 40%;
      }
      .skl-btn {
        margin-top: 24px;
        height: 100%;
        display: block;
        height: 40px;
        width: 140px;
        span {
          min-height: inherit;
          height: inherit;
        }
      }
    }
  }
}
/*Skeleton About Us Ends*/
/*Homepage About Us Ends*/
