/*Blog Listings Starts*/
.blog-container {
  max-width: 1142px;
  padding: 0 23px;
  margin-left: auto;
  margin-right: auto;
  @include ultra-mobile {
    padding: 0 12px;
  }
  .cus-breadcrumb {
    padding: 20px 0;
  }
  .bl-topinfo {
    @include flex-start-center;
    background-color: $secondary;
    margin-bottom: 32px;
    @include ultra-mobile {
      margin-bottom: 16px;
    }
    .blbanner-info {
      width: calc(100% - 672px);
      @include desktop {
        width: 40%;
      }
      @include tablet {
        width: 70%;
      }
      @include ultra-mobile {
        width: 100%;
        padding: 32px 16px 24px 16px;
      }
      .section-desc {
        @include flex-start-start;
        @include prefix(flex-direction, column);
        max-width: 296px;
        margin: 0 auto;
        @include tablet {
          max-width: 85%;
        }
        @include ultra-mobile {
          max-width: 100%;
        }
        @include ultra-mobile {
          @include prefix(align-items, center);
        }
        h4 {
          margin-bottom: 8px;
          color: $dark-high;
          @include tablet {
            margin-bottom: 4px;
          }
          @include ultra-mobile {
            margin-bottom: 8px;
          }
        }
        p {
          color: $dark-high;
          @include ultra-mobile {
            text-align: center;
          }
        }
        .input-action {
          position: relative;
          width: 100%;
          margin-bottom: 16px;
          @include tablet {
            margin-bottom: 8px;
          }
          @include ultra-mobile {
            margin-bottom: 16px;
          }
          .footer-newsletter {
            margin-top: 12px;
            margin-bottom: 0;
            @include tablet {
              margin-top: 0;
            }
            @include ultra-mobile {
              margin-top: 8px;
            }
          }
          .submit-action {
            background-color: $primary;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border: 0;
            padding: 0 12px;
            height: 48px;
            @include prefix(border-top-right-radius, 4px);
            @include prefix(border-bottom-right-radius, 4px);
            position: absolute;
            bottom: 0;
            right: 0;
            &:hover {
              background-color: $button-hover;
            }
          }
        }
      }
    }
    .blbanner-img {
      @include ultra-mobile {
        display: none;
      }
    }
  }
  .bl-contentwrap {
    @include flex-between-start;
    @include ultra-mobile {
      @include prefix(flex-direction, column);
    }
    .list-items {
      @include flex-between-start;
      @include prefix(flex-wrap, wrap);
      width: calc(100% - 352px);
      @include desktop {
        width: 70%;
      }
      @include tablet {
        width: 62%;
      }
      @include ultra-mobile {
        width: 100%;
      }
      .item {
        width: calc(50% - 12px);
        margin-bottom: 36px;
        @include ultra-mobile {
          width: 100%;
        }
        .info-section {
          @include flex-start-start;
          @include prefix(flex-direction, column);
          padding-top: 6px;
          .cat-name {
            color: $secondary;
          }
          .extra-info {
            padding-top: 2px;
            @include flex-start-start;
            .author {
              color: $mid;
              a {
                margin-left: 2px;
                color: $secondary;
              }
            }
            .pub-date {
              color: $mid;
              @include flex-start-center;
              &::before {
                content: " ";
                height: 16px;
                width: 1px;
                background-color: $line;
                display: inline-block;
                margin-left: 8px;
              }
              p {
                padding-left: 8px;
                color: $mid;
              }
            }
          }
        }
      }
    }
    .list-sidebar {
      width: calc(100% - 784px);
      @include desktop {
        width: 27%;
      }
      @include tablet {
        width: 35%;
      }
      @include ultra-mobile {
        width: 100%;
      }
      .box {
        padding: 16px;
        @include prefix(box-shadow, 0px 2px 16px 4px rgba(40, 44, 63, 0.07));
        @include prefix(border-radius, 4px);
        .box-title {
          color: $mid;
          margin-bottom: 10px;
        }
        &:not(:first-child) {
          margin-top: 32px;
          @include ultra-mobile {
            margin-top: 16px;
          }
        }
      }
      .sear-blog {
        .input-groups.search {
          position: relative;
          &:before {
            content: " ";
            background-image: url("../../Assets/Images/search.svg");
            @include hw(24px);
            position: absolute;
            top: 12px;
            left: 12px;
          }
          label {
            display: none;
          }
          input {
            padding-left: 48px;
            &::placeholder {
              color: $high;
            }
          }
        }
        .sear-action {
          .fill-btn {
            width: 100%;
          }
        }
      }
      .recent-posts {
        .ritem {
          padding-bottom: 18px;
          &:not(:last-child) {
            border-bottom: 1px solid $line;
          }
          &:not(:first-child) {
            padding-top: 16px;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      .categories {
        .citem {
          padding-bottom: 18px;
          &:not(:last-child) {
            border-bottom: 1px solid $line;
          }
          &:not(:first-child) {
            padding-top: 16px;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

/*Blog Listings Skeleton Starts*/
.blog-container.skl {
  .bread-c {
    max-width: 20%;
    margin: 20px 0;
  }
  .bl-topinfo.skl {
    background-color: transparent;
    min-height: 400px;
    height: 100%;
    display: block;
    span {
      min-height: inherit;
      height: inherit;
    }
  }
  .bl-contentwrap.skl {
    .item {
      min-height: 200px;
      height: 100%;
      display: block;
      span {
        min-height: inherit;
        height: inherit;
      } 
      .additional {
        margin-top: 12px;
        min-height: 40px;
        height: 100%;
        display: block;
        span {
          min-height: inherit;
          height: inherit;
        } 
      }
    }
    .box {
      .box-title {
        max-width: 45%;
      }
    }
    .list-sidebar.skl {
      .sear-input, .sear-action {
        min-height: 48px;
        height: 100%;
        display: block;
        margin-top: 12px;
        span {
          min-height: inherit;
          height: inherit;
        } 
      }
      .recent-list {
        span {
          margin: 8px 0;
        }
      }
    }
  }
}
/*Blog Listings Skeleton Ends*/

/*Blog Listings Ends*/
/*Blog Details Starts*/

.blogdetail-wrap {
  .blog-section {
    @include flex-between-start;
    @include ultra-mobile {
      @include prefix(flex-direction, column);
    }
    .left-content {
      width: calc(100% - 352px);
      @include desktop {
        width: 65%;
      }
      @include ultra-mobile {
        width: 100%;
      }
      .extra-info {
        padding-top: 8px;
        @include flex-start-start;
        .author {
          color: $mid;
          a {
            margin-left: 2px;
            color: $secondary;
          }
        }
        .pub-date {
          color: $mid;
          @include flex-start-center;
          &::before {
            content: " ";
            height: 16px;
            width: 1px;
            background-color: $line;
            display: inline-block;
            margin-left: 8px;
          }
          p {
            padding-left: 8px;
          }
        }
      }
      .blog-img {
        margin-top: 20px;
      }
      .blog-content {
        max-width: 616px;
        margin: 32px auto;
        @include ultra-mobile {
          max-width: 100%;
          padding-left: 16px;
          padding-right: 16px;
          margin: 16px auto 32px auto;
        }
        .subtitle {
          margin-bottom: 16px;
        }
        img {
          margin-top: 30px;
          margin-bottom: 8px;
        }
        .img-title {
          text-align: center;
          margin-bottom: 28px;
          color: $mid;
          @include ultra-mobile {
            margin-bottom: 12px;
          }
        }
        .actionbar {
          margin-top: 32px;
          padding: 24px 0;
          border-top: 1px solid $line;
          border-bottom: 1px solid $line;
          img {
            max-width: 424px;
            margin: 0;
          }
          @include ultra-mobile {
            margin-top: 26px;
          }
        }
      }
    }
    .right-content {
      width: calc(100% - 784px);
      @include desktop {
        width: 32%;
      }
      @include ultra-mobile {
        width: 100%;
      }
      .box {
        padding: 16px;
        @include prefix(box-shadow, 0px 2px 16px 4px rgba(40, 44, 63, 0.07));
        @include prefix(border-radius, 4px);
        .box-title {
          color: $mid;
          margin-bottom: 10px;
        }
        &:not(:first-child) {
          margin-top: 32px;
          @include ultra-mobile {
            margin-top: 16px;
          }
        }
      }
      .sear-blog {
        .input-groups.search {
          position: relative;
          &:before {
            content: " ";
            background-image: url("../../Assets/Images/search.svg");
            @include hw(24px);
            position: absolute;
            top: 12px;
            left: 12px;
          }
          label {
            display: none;
          }
          input {
            padding-left: 48px;
            &::placeholder {
              color: $high;
            }
          }
        }
        .sear-action {
          .fill-btn {
            width: 100%;
          }
        }
      }
      .recent-posts {
        .ritem {
          padding-bottom: 18px;
          &:not(:last-child) {
            border-bottom: 1px solid $line;
          }
          &:not(:first-child) {
            padding-top: 16px;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      .categories {
        .citem {
          padding-bottom: 18px;
          &:not(:last-child) {
            border-bottom: 1px solid $line;
          }
          &:not(:first-child) {
            padding-top: 16px;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

/*Blog Details Skeleton Starts*/

.blogdetail-wrap.skl {
  .bread-skl {
    max-width: 20%;
    margin: 20px 0;
  }
  .extra-infoskl {
    max-width: 45%;
    margin-top: 4px;
  }
  .blog-img {
    min-height: 320px;
    height: 100%;
    display: block;
    margin-top: 12px;
    span {
      min-height: inherit;
      height: inherit;
    }
    &.in {
      .inside-img {
        max-width: 20%;
        margin: 4px auto 16px auto;
      }
    }
  }
  .blog-content.skl {
    .subtitle {
      max-width: 30%;
      margin-bottom: 12px;
    }
    .para-skl {
      margin-top: 8px;
      &.one {
        min-height: 64px;
        height: 100%;
        display: block;
        span {
          min-height: inherit;
          height: inherit;
        } 
      }
      &.two, &.three {
        min-height: 48px;
        height: 100%;
        display: block;
        span {
          min-height: inherit;
          height: inherit;
        } 
      }
      &.five, &.six, &.sev {
        min-height: 48px;
        height: 100%;
        display: block;
        margin-top: 4px;
        span {
          min-height: inherit;
          height: inherit;
        }
      }
      &.eight {
        min-height: 56px;
        height: 100%;
        display: block;
        span {
          min-height: inherit;
          height: inherit;
        }
      }
    }
    .actionbar span {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 20px;
      @include prefix(border-radius, 50%);
    }
  }
  .right-content.skl {
    .sear-input, .sear-action {
      min-height: 48px;
      height: 100%;
      display: block;
      margin-top: 12px;
      span {
        min-height: inherit;
        height: inherit;
      } 
    }
    .recent-list {
      span {
        margin: 8px 0;
      }
    }
  }
}

/*Blog Details Skeleton Ends*/

/*Blog Details Ends*/
