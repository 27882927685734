/*----------------Variables Declaration Starts----------------*/

$MarcellusFont: 'Marcellus';
$InterFont: 'Inter';

$high: #212B36;
$mid: #637381;
$low: #919eab;
$dark-high: #ffffff;
$dark-mid: #CCCDD7;
$dark-low: #919eab;
$line: #cccccc;
$success: #3eb658;
$error: #f94c43;
$primary: #2E3A50;
$secondary: #B2BFC5;
$surface: #F7FAFC;
$white: #fff;
$black: #000000;

$button-hover: #3E4B51;
$cardbg: #EAEAEA;
$arrowhover: #E1EBEB;

/*----------------Variables Declaration Ends----------------*/