.categories {
  .custom-container {
    .nodata-found {
      margin: 12px 0;
    }
    .cus-breadcrumb {
      margin: 12px 0;
      text-transform: capitalize;
    }
    .cat-banner {
      @include flex-start-center;
      background: $secondary;
      @include tablet {
        flex-wrap: wrap;
        background: transparent;
      }
      .cat-txt {
        width: 32%;
        max-width: 360px;
        margin: 0 auto;
        @include desktop {
          max-width: 40%;
          padding: 0 12px;
        }
        @include tablet {
          max-width: 100%;
        }
        @include tablet {
          height: auto;
          text-align: center;
          background-color: $white;
          width: 100%;
          padding: 16px 0;
          @include prefix(box-shadow, 0px 9px 22px rgb(40 44 63 / 7%));
        }
        h6 {
          color: $white;
          padding-bottom: 16px;
          @include desktop {
            padding-bottom: 12px;
          }
          @include tablet {
            color: $high;
            padding-bottom: 0;
          }
        }
        p {
          color: $white;
          margin-bottom: 35px;
          @include media-1299-below {
            margin-bottom: 16px;
          }
          @include desktop {
            display: block;
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          @include tablet {
            display: none;
          }
        }
        .cat-service {
          @include flex-start-center;
          margin-bottom: 16px;
          @include desktop {
            margin-bottom: 12px;
          }
          @include tablet {
            display: none;
          }
          .cat-service-img {
            margin-right: 16px;
            img {
              height: 48px;
              width: 48px;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          .cat-service-txt {
            p {
              margin-bottom: 0;
            }
          }
        }
      }
      .cat-banner-img {
        width: 68%;
        @include tablet {
          order: -1;
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
    }
    .product-list {
      @include flex-start-center;
      flex-wrap: wrap;
      gap: 0 24px;
      &.loading {
        display: block;
      }
      .product-list {
        margin-top: 24px;
      }
      .product-list-item {
        flex-basis: calc(calc(100% / 3) - 16px);
        margin-bottom: 27px;
        @include ultra-mobile {
          flex-basis: calc(100%);
          margin-bottom: 24px;
        }
        @include tablet-above {
          max-width: 424px;
        }
        .product-list-item-txt {
          span {
            padding: 9px 0 4px;
            display: block;
          }
        }
        .price-wrapper {
          padding-top: 0;
        }
      }
      .loading-text {
        width: 100%;
        text-align: center;
        margin: 8px 0;
        @include flex-center-center;
        @include fonts($MarcellusFont, $high, 36px, 400, 44px, 0.02em);
        .loader-black {
          margin-left: 24px;
          display: inline-block;
          left: 0;
        }
      }
    }
  }
}

/*Product Listing Skeleton Starts*/
.categories.skl {
  padding-top: 20px;
  width: 100%;
  .bread-c {
    max-width: 25%;
  }
  .product-list-item.skl {
    .img-skl {
      min-height: 280px;
      height: 100%;
      display: block;
      @include desktop {
        min-height: 200px;
      }
      @include tablet {
        min-height: 160px;
      }
      @include ultra-mobile {
        min-height: 200px;
      }
      span {
        min-height: inherit;
        height: inherit;
      }
    }
    .name-skl {
      max-width: 50%;
      margin-top: 4px;
    }
    .price-skl {
      max-width: 35%;
      margin-top: 4px;
    }
  }
}

.cat-banner.skl {
  padding-top: 20px;
  background-color: transparent !important;
  min-height: 400px;
  height: 100%;
  display: block;
  span {
    min-height: inherit;
    height: 100%;
    width: 100%;
  }
  @include ultra-mobile {
    margin: 0;
    min-height: 220px;
  }
}
/*Product Listing Skeleton Ends*/