.freesample-popup {
  .modal-dialog {
    max-width: 768px;
    @include tablet {
      max-width: 95%;
    }
    @include ultra-mobile {
      max-width: 100%;
      margin: 0;
    }
    .modal-body {
      .sample-tab-main {
        @include flex-between-end;
        @include ultra-mobile {
          @include prefix(flex-wrap, wrap);
        }
        .sample-right {
          width: 224px;
          text-align: center;
          @include ultra-mobile {
            width: 100%;
            order: 1;
          }
          img {
            height: 160px;
            width: 160px;
            margin: 0 auto;
          }
          p {
            padding: 17px 0px 50px;
            @include ultra-mobile {
              padding: 17px 0px 27px;
            }
          }
          .fill-btn {
            width: 100%;
            @include ultra-mobile {
              width: 152px;
            }
          }
        }
      }
      .sample-left {
        .sample-wrap {
          @include ultra-mobile {
            width: 100%;
          }
          .box-title {
            text-transform: capitalize;
          }
          p {
            padding-bottom: 4px;
            &.tm {
              margin-top: 5px;
              padding-bottom: 0;
            }
            @include ultra-mobile {
              display: none;
            }
          }
        }
        .sample-main {
          margin: 11px 0 9px;
          @include ultra-mobile {
            margin: 16px 0 13px;
          }
          &.last-samp {
            margin: 10px 0 0;
            img {
              margin-bottom: 0;
            }
          }
          .sample-checkbox {
            @include flex-start-center;
            @include prefix(flex-wrap, wrap);
            @include ultra-mobile{
              gap: 8px 8px;
              max-width: 312px;
            }
            @include iphone {
              max-width: 248px;
            }
            .samp-tick {
              margin: 0 4px 4px 0;
              width: 28px;
              height: 28px;
              position: relative;
              cursor: pointer;
              @include ultra-mobile {
                height: 56px;
                width: 56px;
                margin: 0;
              }
              label {
                @include ultra-mobile {
                  width: 100%;
                }
                img {
                  width: 28px;
                  height: 28px;
                  @include prefix(border-radius, 4px);
                  border: 1px solid $line;
                  cursor: pointer;
                  &:hover {
                    border: 1px solid $primary;
                  }
                  @include ultra-mobile {
                    width: 100%;
                    height: 56px;
                  }
                }
                span {
                  width: 28px;
                  height: 28px;
                  @include prefix(border-radius, 4px);
                  border: 1px solid $line;
                  display: inline-block;
                  cursor: pointer;
                  &:hover{
                    border:1px solid $primary;
                 }
                  @include ultra-mobile {
                    width: 100%;
                    height: 56px;
                  }
                }
              }
              &.selected {
                &::before {
                  content: "";
                  text-align: center;
                  position: absolute;
                  height: 28px;
                  width: 28px;
                  background: url("../../Assets/Images/check.svg") no-repeat center;
                  @include ultra-mobile {
                    height: 56px;
                    width: 100%;
                  }
                }
                img {
                  border: 1px solid $primary;
                }
              }
            }
          }
        }
        &.left-bottom {
          @include flex-between-end;
          @include ultra-mobile {
            @include prefix(flex-wrap, wrap);
          }
          .sample-wrap {
            width: 416px;
            @include ultra-mobile {
              width: 100%;
              p{
                display: block;
              }
            }
            .sample-checkbox {
              @include flex-start-center;
              @include ultra-mobile {
                gap: 8px;
                margin: auto;
              }
            }
          }
          .action-bar {
            @include ultra-mobile {
              order: -1;
              text-align: center;
              width: 100%;
            }
            .fill-btn {
              width: 224px;
              @include ultra-mobile {
                width: 152px;
                margin: 0 auto 20px auto;
              }
            }
          }
        }
      }
      .nav-tabs {
        display: none;
        @include ultra-mobile {
          border-bottom: 0;
          width: 100%;
        }
        li {
          display: inline-block;
          @include ultra-mobile {
            width: calc(100% / 3 - 6px);
            button {
              width: 100%;
              padding: 3px 0;
              background-color: $white;
              color: $high;
              border: 1px solid $line;
              @include prefix(border-radius, 4px);
              &.active {
                background-color: $primary;
                color: $white;
                border: 1px solid $primary;
              }
            }
          }
        }
        @include ultra-mobile {
          @include flex-start-center;
          gap: 9px;
          margin-bottom: 20px;
        }
      }
      .tab-content {
        max-width: 416px;
        display: inline-block;
        @include ultra-mobile {
          max-width: 100%;
          margin: auto;
        }
        &.active {
          display: block;
        }
        .tab-pane {
          display: inline-block;
          @include ultra-mobile {
            display: none;
          }
          &.fade {
            &:not(.show) {
              opacity: 1;
              @include ultra-mobile {
                opacity: 0;
              }
            }
          }
          &.active {
            display: block;
          }
        }
      }
    }
  }
}
/*Add New Address Popup Starts*/
.step2 {
  @include ultra-mobile {
    background-color: $white;
  }
  .modal-dialog {
    max-width: 768px;
    @include tablet {
      max-width: 85%;
    }
    @include ultra-mobile {
      max-width: 100%;
      margin: 0;
      height: 100%;
      .modal-content {
        height: 100%;
      }
    }
    .modal-header {
      @include ultra-mobile {
        padding: 22px 20px 18px 20px;
      }
    }
    .modal-body {
      @include prefix(flex-wrap, wrap);
      @include flex-between-start;
      @include ultra-mobile {
        padding: 0 20px 32px 20px;
      }
      .input-groups {
        width: calc(50% - 8px);
        margin-bottom: 24px;
        @include ultra-mobile {
          width: 100%;
        }
      }
      .full-street {
        width: 100%;
        .input-groups {
          width: 100%;
        }
      }
      .postcode-wrap {
        @include flex-start-center;
        width: calc(50% - 8px);
        @include ultra-mobile {
          width: 100%;
        }
        .input-groups {
          display: inline-block;
          width: calc(100% - 168px);
          input {
            border-right: 0;
            @include prefix(border-top-right-radius, 0px);
            @include prefix(border-bottom-right-radius, 0px);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .fill-btn {
          @include prefix(border-top-left-radius, 0px);
          @include prefix(border-bottom-left-radius, 0px);
          width: 168px;
          padding: 0 23px;
        }
      }
      .action-bar {
        flex-basis: 100%;
        width: 100%;
        text-align: right;
        margin-top: 8px;
        .fill-btn,
        .outline-btn {
          width: 224px;
          margin-right: 16px;
          @include ultra-mobile {
            width: 100%;
            width: calc(100% / 2 - 8px);
          }
        }
        .fill-btn {
          margin-right: 0;
          @include ultra-mobile {
            margin-right: 0;
          }
        }
      }
    }
  }
}
/*Add New Address Popup Ends*/
