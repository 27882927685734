.cart-page-wrap {
  .cart-section {
    max-width: 1096px;
    margin: 64px auto;
    @include flex-between-start;
    @include tablet {
      @include prefix(flex-direction, column);
    }
    @include ultra-mobile {
      margin: 24px auto 32px auto;
    }
    @include custom-tablet {
      margin: 44px auto;
    }
    .cart-info {
      width: calc(100% - 416px);
      @include desktop {
        width: 65%;
      }
      @include tablet {
        width: 100%;
      }
      h6 {
        padding-bottom: 16px;
        border-bottom: 1px solid $line;
      }
      .cart-items {
        .item {
          @include flex-between-start;
          padding: 16px 0 24px 0;
          border-bottom: 1px solid $line;
          .ct-img {
            max-width: 160px;
            @include ultra-mobile {
              max-width: 88px;
            }
          }
          .ct-details {
            width: calc(100% - 176px);
            @include ultra-mobile {
              width: calc(100% - 96px);
            }
            @include flex-between-start;
            @include prefix(flex-wrap, wrap);
            .ct-top {
              width: calc(100% - 112px);
              @include ultra-mobile {
                width: 100%;
              }
              .attributes {
                padding-top: 6px;
                @include prefix(display, flex);
                @include prefix(flex-wrap, wrap);
                p {
                  color: $mid;
                  position: relative;
                  margin-bottom: 12px;
                  @include ultra-mobile {
                    margin-bottom: 8px;
                  }
                  span {
                    text-transform: initial;
                  }
                  &:not(:first-child) {
                    padding-left: 12px;
                    &::before {
                      content: " ";
                      background-color: $line;
                      @include hw(4px);
                      display: inline-block;
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      left: 4px;
                    }
                  }
                }
              }
            }
            .price-wrapper {
              @include prefix(flex-direction, column);
              padding-top: 0;
              text-align: right;
              @include ultra-mobile {
                @include prefix(flex-direction, row);
                padding-bottom: 10px;
              }
              .normal-price {
                @include fonts($InterFont, $high, 16px, 500, 24px, 0.15px);
              }
              .product-price {
                padding-left: 0;
                @include fonts($InterFont, $mid, 16px, 500, 24px, 0.15px);
                @include ultra-mobile {
                  padding-left: 8px;
                }
              }
              .special-price {
                @include fonts($InterFont, $high, 16px, 500, 24px, 0.15px);
              }
            }
            .inc-dec-box {
              flex-basis: 100%;
              text-transform: uppercase;
              @include flex-start-center;
              .inc-dec-wrapper {
                padding: 3px 4px;
              }
              p {
                color: $mid;
                padding-right: 8px;
              }
            }
            .ct-actions {
              flex-basis: 100%;
              padding-top: 12px;
              .remove {
                color: $low;
                border-bottom: 1px solid $low;
                margin-right: 16px;
              }
              .wishlist {
                color: $low;
                border-bottom: 1px solid $low;
              }
            }
          }
        }
        .mobile-ct-actions {
          .sub-actions {
            @include flex-between-center;
            border-bottom: 1px solid $line;
            padding-bottom: 16px;
            padding-top: 12px;
            a {
              flex-basis: 50%;
              text-align: center;
              span {
                color: $low;
                border-bottom: 1px solid $low;
              }
              &:first-child {
                border-right: 1px solid $line;
              }
            }
          }
        }
      }
    }
    .cart-summary {
      width: calc(100% - 744px);
      @include desktop {
        width: calc(calc(100% - 65%) - 32px);
      }
      @include tablet {
        width: 100%;
        margin-top: 30px;
      }
      .total-table {
        padding-top: 36px;
        padding-bottom: 14px;
        border-bottom: 1px solid $line;
        .sub-col {
          @include flex-between-start;
          .stitle {
            width: 100%;
            max-width: 264px;
            @include desktop {
              width: auto;
            }
          }
          .svalue {
            width: calc(100% - 264px);
            text-align: right;
            @include desktop {
              width: auto;
            }
          }
          &:not(:last-child) {
            padding-bottom: 12px;
          }
        }
      }
      .discount-box {
        margin: 24px 0;
        .extra-ip-groups {
          position: relative;
          input {
            display: block;
            border: 1px solid $line;
            box-sizing: border-box;
            height: 48px;
            width: 100%;
            padding: 0 16px;
            outline: none !important;
            background: $white;
            @include prefix(box-shadow, none);
            @include prefix(border-radius, 4px);
            @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
          }
          .dis-btn {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            border: 0;
            background-color: transparent;
            @include hw(16px);
            padding: 0;
          }
        }
      }
      .order-total {
        padding-top: 14px;
        border-top: 1px solid $line;
        .maintotal {
          @include flex-between-start;
          .stitle {
            width: 100%;
            max-width: 264px;
            @include desktop {
              width: auto;
            }
          }
          .svalue {
            width: calc(100% - 264px);
            text-align: right;
            @include desktop {
              width: auto;
            }
          }
        }
      }
      .action-bar {
        padding-top: 18px;
        .fill-btn {
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }
  }
  .cart-sticky-bar {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1;
    @include prefix(box-shadow, 0px 2px 16px 4px rgba(40, 44, 63, 0.07));
    background-color: $white;
    .act-bar {
      padding: 8px 12px;
      @include flex-between-center;
      .fill-btn {
        padding: 0 40px;
      }
      span {
        color: $mid;
      }
    }
  }
  /*Skeleton Starts*/
  .cart-section {
    .cart-info.skl {
      .titleskl {
        span {
          max-width: 40%;
        }
      }
      .ct-img {
        min-height: 120px;
        height: 100%;
        display: block;
        width: 100%;
        @include tablet {
          min-height: 66px;
        }
        span {
          min-height: inherit;
          height: inherit;
        }
      }
      .cart-items {
        .item {
          .ct-details {
            .ct-top {
              .product-name {
                max-width: 50%;
              }
              .attributes {
                display: block;
                max-width: 90%;
              }
            }
          }
          .price-wrapper {
            display: block;
            width: 15%;
            @include tablet {
              @include prefix(display, flex);
              @include prefix(flex-direction, row);
              width: 50%;
              margin-top: 12px;
              flex-basis: 100%;
              p {
                width: 35%;
                &:first-child {
                  margin-right: 8px;
                }
              }
            }
          }
          .inc-dec-box {
            display: block;
            margin-top: 0;
            flex-basis: 15%;
            @include tablet {
              flex-basis: 45%;
            }
          }
          .ct-actions.skl {
            margin-top: 12px;
            @include prefix(display, flex);
            p {
              width: 15%;
              margin-right: 16px;
              &:last-child {
                width: 30%;
              }
            }
          }
        }
        .sub-actions.skl {
          span {
            width: 50%;
            text-align: center;
            &:first-child {
              border-right: 1px solid $line;
            }
          }
        }
      }
    }
    .cart-summary.skl {
      p.tm {
        max-width: 40%;
      }
      .total-table {
        .sub-col {
          &.total {
            .stitle {
              max-width: 30%;
            }
          }
          .stitle {
            max-width: 60%;
          }
          .stitle {
            @include tablet {
              max-width: 40%;
            }
          }
          .svalue {
            @include tablet {
              max-width: 20%;
            }
          }
          p {
            @include tablet {
              width: 100%;
            }
          }
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          .sub-col {
            p {
              width: 100%;
              &.svalue {
                max-width: 25%;
              }
            }
          }
        }
      }
      .discount-box {
        .extra-ip-groups {
          min-height: 48px;
          height: 100%;
          display: block;
          width: 100%;
          span {
            min-height: inherit;
            height: inherit;
          }
        }
      }
      .order-total {
        .maintotal {
          .stitle {
            max-width: 50%;
            @include tablet {
              max-width: 40%;
            }
          }
          .svalue {
            @include tablet {
              max-width: 20%;
            }
          }
          p {
            @include tablet {
              width: 100%;
            }
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            p {
              width: 100%;
              &.svalue {
                max-width: 25%;
              }
            }
          }
        }
      }
      .action-bar {
        .btn {
          min-height: 48px;
          height: 100%;
          display: block;
          width: 100%;
          padding-left: 0;
          padding-right: 0;
          @include tablet {
            &:first-child {
              display: none;
            }
          }
          span {
            min-height: inherit;
            height: inherit;
          }
        }
      }
    }
  }
  /*Skeleton Ends*/
}

/*apply coupon starts*/
.modal-backdrop {
  @include ultra-mobile {
    &.show {
      opacity: 1;
    }
  }
}
.coupon-popup {
  @include ultra-mobile {
    background-color: $white;
  }
  .modal-dialog {
    max-width: 400px;
    @include ultra-mobile {
      max-width: 100%;
      align-items: flex-start;
      margin: 0;
    }
    .modal-header {
      @include ultra-mobile {
        padding: 22px 20px 18px 20px;
      }
    }
    .modal-body {
      @include ultra-mobile {
        padding: 0 20px 0;
      }
      .coupon-wrap {
        @include flex-start-start;
        @include ultra-mobile {
          width: 100%;
        }
        .input-groups {
          width: 100%;
          margin-bottom: 0;
          label {
            display: none;
          }
          input {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .fill-btn {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
/*apply coupon end*/
