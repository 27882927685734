.cms-wrap {
	.custom-container {
		max-width: 1142px;
		margin: auto;
		.cus-breadcrumb {
			margin: 12px 0 20px;
		}
		h6 {
			margin-bottom: 17px;
			text-align: center;
		}
	}
}

/*Contact Us Starts*/
.cms-wrap {
	&.contact {
		padding: 64px 0 32px 0;
		.bm {
			margin-bottom: 28px;
		}
		@include ultra-mobile {
			padding: 32px 0 8px;
		}
		h6 {
			text-align: left;
			margin: 0 auto 17px auto;
			@include ultra-mobile {
				margin: 0 auto 8px auto;
			}
		}
		.contact-cont-wrap {
			@include flex-between-start;
			@include ultra-mobile {
				flex-wrap: wrap;
			}
			.contact-cont {
				flex-basis: 512px;
				@include custom-tablet {
					flex-basis: 48%;
				}
				@include ultra-mobile {
					flex-basis: 100%;
				}
				span {
					@include ultra-mobile {
						display: block;
					}
				}
				.contact-info {
					margin-bottom: 33px;
					.tm {
						margin-bottom: 11px;
						display: block;
						@include ultra-mobile {
							margin-bottom: 16px;
						}
					}
					.bm {
						color: $high;
						margin-bottom: 4px;
					}
				}
				&.contact-form {
					@include ultra-mobile {
						padding-left: 0;
					}
					.bm {
						margin-bottom: 12px;
						@include ultra-mobile {
							margin-bottom: 16px;
						}
					}
					.input-groups {
						margin-bottom: 24px;
					}
					.fill-btn {
						width: 100%;
					}
					textarea {
						height: 96px;
						border: 1px solid #cccccc;
						box-sizing: border-box;
						padding: 4px 16px;
						@include prefix(border-radius, 4px);
						&.error {
							border-color: $error;
						}
					}
				}
			}
		}
	}
}
/*Contact Us Ends*/

/*About Us Starts*/

.cms-wrap {
	&.about {
		padding-bottom: 12px;
		@include ultra-mobile {
			padding-bottom: 0;
		}
		h6{
			margin-bottom: 24px;
		}
		.bm {
			margin-bottom: 20px;
			@include ultra-mobile {
				&:last-child {
					margin-bottom: 0px;
				}
			}
		}
		.abt-content {
			margin: 33px 0 31px;
			@include mobile {
				margin: 17px 0 0;
				padding: 0;
			}
			.about-inn-img {
				margin-bottom: 33px;
				@include mobile {
					margin: 0 0 17px;
				}
			}
			.about-inn-img {
				@include flex-between-center;
				.about-img {
					width: calc(100% / 2);
					&.right-img {
						padding-right: 6px;
						@include mobile {
							padding-right: 2px;
						}
					}
					&.left-img {
						padding-left: 6px;
						@include mobile {
							padding-left: 2px;
						}
					}
				}
			}
		}
	}
}

/*About Us Skeleton Starts*/
.cms-wrap.about.skl {
	.breadskl {
		padding: 20px 0 28px 0;
		max-width: 20%;
		@include ultra-mobile {
			max-width: 40%;
		}
	}
	h6 {
		max-width: 20%;
		margin: 0 auto 24px auto;
		@include ultra-mobile {
			max-width: 40%;
		}
	}
	.img.skl {
		min-height: 320px;
		height: 100%;
		display: block;
		span {
			min-height: inherit;
			height: inherit;
		}
		@include ultra-mobile {
			min-height: 136px;
		}
	}
	.abt-content {
		.bm {
			min-height: 48px;
			height: 100%;
			display: block;
			span {
				min-height: inherit;
				height: inherit;
			}
			@include ultra-mobile {
				min-height: 96px;
			}
			&.short {
				min-height: 32px;
				@include ultra-mobile {
					min-height: 52px;
				}
			}
		}
		.about-inn-img {
			.about-img {
				min-height: 120px;
				height: 100%;
				display: block;
				span {
					min-height: inherit;
					height: inherit;
				}
				@include ultra-mobile {
					min-height: 64px;
				}
			}
		}
	}
}
/*About Us Skeleton Ends*/

/*About Us Ends*/

/*Construction Starts*/
.cms-wrap {
	&.construction {
		.bm {
			margin-bottom: 20px;
			@include ultra-mobile {
				margin-bottom: 24px;
			}
		}
		h6{
			@include ultra-mobile {
				margin-bottom: 9px;
			}
		}
		.construc-inn {
			@include flex-between-center;
			padding: 6px 0 32px;
			@include ultra-mobile {
				flex-wrap: wrap;
				padding: 0 0 8px;
			}
			.construc-img {
				width: calc(100% / 2);
				@include ultra-mobile {
					width: calc(100%);
				}
				&.right-img {
					padding-right: 12px;
					@include ultra-mobile {
						padding: 0 0 8px 0;
					}
				}
				&.left-img {
					padding-left: 12px;
					@include ultra-mobile {
						padding: 8px 0;
					}
				}
			}
		}
	}
}

/*Construction Skeleton Starts*/
.cms-wrap.construction.skl {
	.breadskl {
		padding: 20px 0 28px 0;
		max-width: 20%;
		@include ultra-mobile {
			max-width: 40%;
		}
	}
	h6 {
		max-width: 20%;
		margin: 0 auto 24px auto;
		@include ultra-mobile {
			max-width: 40%;
		}
	}
	.construction-content {
		.bm {
			min-height: 48px;
			height: 100%;
			display: block;
			span {
				min-height: inherit;
				height: inherit;
			}
			@include ultra-mobile {
				min-height: 96px;
			}
		}
		.construc-inn {
			.construc-img {
				min-height: 360px;
				height: 100%;
				display: block;
				span {
					min-height: inherit;
					height: inherit;
				}	
			}
		}
	}
}
/*Construction Skeleton Ends*/

/*Construction Ends*/

/*Privacy Policy Starts*/
.cms-wrap {
	&.privacy {
		padding-bottom: 31px;
		@include mobile {
			padding-bottom: 8px;
		}
		span {
			display: block;
		}
		.bm {
			color: $mid;
		}
		.privacy-wrap {
			.privacy-cont {
				margin-top: 25px;
				p {
					margin-bottom: 12px;
				}
				.tm {
					margin-bottom: 11px;
				}
				ul {
					padding: 0 0 0 20px;
					margin-bottom: 20px;
					li {
						list-style-type: disc;
						color: $mid;
						&::marker {
							color: $mid;
						}
						a {
							color: $mid;
							padding-bottom: 0px;
							border-bottom: 1px solid $mid;
						}
					}
				}
				.table-responsive {
					margin-top: 8px;
					tbody,
					td,
					tfoot,
					th,
					thead,
					tr {
						border-color: $line;
					}
					@include mobile {
						white-space: nowrap;
					}
					.table {
						margin-bottom: 0;
						thead {
							th {
								padding: 8px 0 11px 15px;
								color: $mid;
							}
						}
						tbody {
							tr {
								td {
									color: $mid;
									padding: 8px 0 11px 15px;
									&:first-child {
										font-weight: 500;
									}
								}
							}
							&:not(:first-child) {
								border-top: 0px;
							}
						}
					}
				}
			}
		}
	}
}
/*Privacy Policy Ends*/

/*Finance Starts*/
.finance-wrap {
	padding: 0 0 32px;
	text-align: center;
	@include ultra-mobile {
		padding: 0 0 8px;
		text-align: left;
		h6 {
			text-align: center;
		}
	}
	.custom-container {
		max-width: 1142px;
		margin: auto; 
		.cus-breadcrumb {
			margin: 12px 0 20px;
		}
		.finance-top-content {
			h6 {
				padding-bottom: 9px;
			}
			img {
				width: auto;
				margin: 27px auto 12px;
				@include mobile {
					width: 100%;
					margin: 23px 0 10.68px 0;
				}
			}
			.payment-begin {
				max-width: 423px;
				margin: 0 auto 29px auto;
				@include mobile {
					max-width: 100%;
					margin: 0 auto 21px;
				}
				span {
					color: $mid;
					display: block;
				}
			}
			.bm {
				a {
					border-bottom: 1px solid $high;
					&:hover {
						border-color: transparent;
					}
				}
			}
		}
		.finance-mid-content {
			padding: 67px 0 0;
			@include mobile {
				padding: 27px 0 0;
			}
			h6 {
				padding-bottom: 24px;
			}
			.finance-plans {
				@include flex-between-center;
				padding-bottom: 64px;
				@include mobile {
					flex-wrap: wrap;
					padding-bottom: 32px;
				}
				.plan-img {
					width: calc(100% / 4);
					margin-right: 24px;
					@include mobile {
						width: calc(100%);
						margin: 0 auto 16px auto;
						padding: 0;
						max-width: 256px;
					}
					&:last-child {
						margin-right: 0;
						@include mobile {
							margin: 0 auto;
						}
					}
				}
			}
		}
		.finance-last-content {
			h6 {
				padding-bottom: 24px;
			}
			.fin-opt {
				max-width: 376px;
				margin: auto;
			}
		}
	}
}
/*Finance Ends*/

/*Testimonials Starts*/
.cms-wrap {
	&.testimo {
		.custom-container {
			h6 {
				margin-bottom: 14px;
			}
		}
		.sub-line {
			text-align: center;
			@include ultra-mobile {
				@include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
			}
		}
		.testimo-details {
			margin-top: 16px;
			@include ultra-mobile {
				margin-top: 30px;
			}
			.t-item {
				margin-bottom: 26px;
				img {
					margin-bottom: 6px;
				}
				.bm {
					text-align: center;
					@include ultra-mobile {
						width: 100%;
					}
				}
				.desc-sec {
					p {
						margin-top: 12px;
						text-align: left;
						color: $mid;
					}
				}
				.name-sec {
					p {
						margin-top: 8px;
					}
				}
			}
		}
	}
}
/*Testimonials Ends*/

/*Measuring Guide Starts*/
.cms-wrap {
	&.mguide {
		padding-top: 64px;
		padding-bottom: 32px;
		@include ultra-mobile {
			padding-top: 32px;
			padding-bottom: 8px;
		}
		.mg-details {
			p {
				margin-bottom: 20px;
			}
		}
		.action-bar {
			margin-top: 16px;
			margin-bottom: 32px;
			text-align: center;
			.fill-btn {
				width: 352px;
				@include ultra-mobile {
					width: 100%;
				}
				&:not(:last-child) {
					margin-right: 16px;
					@include ultra-mobile {
						margin-right: 0;
						margin-bottom: 16px;
					}
				}
			}
		}
	}
}
/*Measuring Guide Ends*/

/*FAQs Page Starts*/
.faq-wrap{
	text-align: center;
	padding-bottom: 32px;
	@include ultra-mobile {
		padding-bottom: 8px;
	}
	.custom-container {
		max-width: 1096px;
		margin: auto;
		padding: 0;
		@include ultra-mobile {
			padding: 0 12px;
		}
		.cus-breadcrumb{
			margin: 12px 0 20px;
		}
		h6{
			padding-bottom: 17px;
			@include ultra-mobile {
				padding-bottom: 9px;
			}
		}
		p{
			&.bm{
				max-width: 960px;
				margin: auto;
			}
		}
		.faq-list{
			padding-top: 31px;
			text-align: left;
			@include ultra-mobile {
				padding-top:11px;
			}
			.accordion{
				.accordion-item{
					border-left: 0;
					border-right: 0;
					&:first-child{
						border-top: 0;
					}
					.accordion-header{
						.accordion-button{
							padding: 22px 0px 8px;
							&.collapsed{
								padding: 22px 0px 25px;
							}
							&:not(.collapsed){
								background-color: $dark-high;
								outline: 0;
								box-shadow: unset;
							}
							&:focus{
								outline: 0;
								border-color: unset;
								box-shadow: unset;
							}
							&::after{
								display: none;
							}
							&.tm {
								color: $high !important;
							}
						}
					}
					.accordion-body{
						padding: 0 0 33px;
						@include ultra-mobile {
							padding: 0 0 26px;
						}
						&.bl{
							color: $mid;
							@include ultra-mobile {
								@include fonts($InterFont, $mid, 14px, 400, 20px, 0.25px);
							}
						}
					}
				}
			}
		}
	}
}
/*FAQs Page Ends*/


/*Common CMS Skeleton Starts*/
.skl.cms {
	// padding-top: 36px;
	.breadskl {
		max-width: 25%;
		margin: 24px 0;
	}
	.lrg {
		min-height: 80px;
		height: 100%;
		margin-top: 12px;
		display: block;
		span {
			min-height: inherit;
			height: inherit;
		}
	}
	.med {
		min-height: 64px;
		height: 100%;
		margin-top: 12px;
		display: block;
		span {
			min-height: inherit;
			height: inherit;
		}
	}
	.sml {
		min-height: 40px;
		height: 100%;
		margin-top: 12px;
		display: block;
		span {
			min-height: inherit;
			height: inherit;
		}
	}
	.sections {
		margin: 16px 0;
		@include flex-between-start;
		@include ultra-mobile {
			@include prefix(flex-direction, column);
		}
		.left, .right {
			width: calc(50% - 12px);
			min-height: 480px;
			height: 100%;
			display: block;
			@include ultra-mobile {
				min-height: 200px;
				width: 100%;
				margin-top: 12px;
			}
			span {
				min-height: inherit;
				height: inherit;
			}
		}
	}
}
/*Common CMS Skeleton Ends*/


/*terms and condition page start*/

/*terms and condition page end*/