/*----------------Font Import Starts----------------*/
@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
/*----------------Font Import Ends----------------*/

/*----------------Common Styles Starts----------------*/

.custom-container {
  max-width: 1366px;
  padding-left: 23px;
  padding-right: 23px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .custom-container {
    padding-left: 12px;
    padding-right: 12px;
  }
}

* {
  outline: none !important;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

textarea,
input {
  -webkit-appearance: none;
  @include prefix(border-radius, 0);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
  display: inline-block;
}

a:hover {
  text-decoration: none;
  @include prefix(transition, all 200ms ease-in);
  color: $high;
  cursor: pointer;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.caps-on {
  text-transform: uppercase;
}

h1 {
  @include fonts($MarcellusFont, $high, 57px, 400, 64px, 0.01em);
}

h2 {
  @include fonts($MarcellusFont, $high, 45px, 400, 52px, 0.02em);
}
h3 {
  @include fonts($MarcellusFont, $high, 36px, 400, 44px, 0.02em);
}
h4 {
  @include fonts($MarcellusFont, $high, 32px, 400, 40px, 0.03em);
}
h5 {
  @include fonts($MarcellusFont, $high, 28px, 400, 36px, 0.03em);
}
h6 {
  @include fonts($MarcellusFont, $high, 24px, 400, 32px, 0.03em);
}

.tl {
  @include fonts($InterFont, $high, 22px, 400, 32px, 0);
}
.tm {
  @include fonts($InterFont, $high, 16px, 500, 24px, 0.15px);
}
.ts {
  @include fonts($InterFont, $high, 14px, 600, 20px, 1.6px);
}

.ll {
  @include fonts($InterFont, $high, 14px, 500, 20px, 0.1px);
}
.lm {
  @include fonts($InterFont, $high, 12px, 500, 16px, 0.5px);
}
.ls {
  @include fonts($InterFont, $high, 11px, 500, 16px, 0.5px);
}

.bl {
  @include fonts($InterFont, $high, 16px, 400, 24px, 0.15px);
}
.bm {
  @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
}
.bs {
  @include fonts($InterFont, $high, 12px, 400, 16px, 0.4px);
}

.show-768 {
  @include ultra-mobile {
    display: none;
  }
}
.hide-767 {
  @include ultra-mobile-above {
    display: none;
  }
}
.show-992 {
  @include tablet {
    display: none;
  }
}
.hide-991 {
  @include tablet-above {
    display: none;
  }
}

.show-576 {
  @include mobile-above {
    display: none;
  }
}

.hide-575 {
  @include mobile {
    display: none;
  }
}

/*Price Texts & Variants CSS Starts*/
.price-wrapper {
  padding-top: 8px;
  @include prefix(display, flex);
  .normal-price {
    @include fonts($InterFont, $high, 14px, 500, 20px, 0.1px);
  }
  .product-price {
    text-decoration: line-through;
    padding-left: 8px;
    @include fonts($InterFont, $mid, 14px, 500, 20px, 0.1px);
  }
  .special-price {
    @include fonts($InterFont, $high, 14px, 500, 20px, 0.1px);
  }
}
/*Price Texts & Variants CSS Ends*/

/*Common Skeletion Starts*/
.react-loading-skeleton {
  line-height: normal;
}
/*Common Skeletion Ends*/

/*Popup Close Button Starts*/
.modal {
  .modal-header {
    padding: 24px;
    border: 0;
  }
  .modal-body {
    padding: 0 24px 24px 24px;
  }
  .modal-dialog {
    .modal-content {
      border: 0;
      @include prefix(border-radius, 4px);
      @include ultra-mobile {
        @include prefix(border-radius, 0);
      }
    }
    .modal-header {
      .btn-close {
        @include hw(24px);
        background: transparent url("../../Assets/Images/close.svg");
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        opacity: 1;
        &:focus {
          @include prefix(box-shadow, none);
        }
      }
    }
  }
}
/*Popup Close Button Ends*/

/*Toast Message Design Starts*/
.Toastify {
  max-width: 1366px;
  padding-left: 23px;
  padding-right: 23px;
  margin: 0 auto;
  @include ultra-mobile {
    padding-left: 12px;
    padding-right: 12px;
  }
  .Toastify__toast-container {
    position: inherit;
    position: initial;
    @include prefix(transform, none);
    width: 100%;
    margin-top: 24px;
  }
}
/*Toast Message Design Ends*/

/*Pagination Design Starts*/
.custom-pagination {
  margin-top: 40px;
  @include flex-center-center;
  li {
    @include hw(24px);
    @include flex-center-center;
    background-color: $surface;
    &.selected {
      background-color: $primary;
      color: $dark-high;
    }
    &.disabled {
      opacity: 0.3;
    }
    &:not(:last-child) {
      margin-right: 8px;
    }
    a {
      @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
    }
  }
}
/*Pagination Design Ends*/

/*----------------Common Styles Ends----------------*/

.braintree-option__card {
  display: none !important;
}
