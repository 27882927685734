.top-backskl {
  padding: 20px 24px;
  border-bottom: 1px solid $line;
  margin-bottom: 24px;
  @include prefix(display, flex);
  @include tablet-above {
    display: none;  
  }
  span {
    &:first-child {
      width: 24px;
      height: 24px;
    }
    &:last-child {
      margin-left: 12px;
      width: 50%;
      span {
        width: 100%;
      }
    }
  }
}

/*My Orders Page Starts*/
.account-container {
  max-width: 1142px;
  margin: 32px auto 32px auto;
  padding: 0 23px;
  @include tablet {
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
  .acc-backbtn {
    @include flex-start-center;
    padding: 16px 20px;
    border-bottom: 1px solid $line;
    margin-bottom: 22px;
    &:before {
      content: " ";
      background-image: url("../../Assets/Images/arrow-left.svg");
      @include hw(24px);
      display: inline-block;
      margin-right: 16px;
    }
  }
  .account-wrap {
    @include flex-between-start;
    @include ultra-mobile {
      padding: 0 12px;
    }
    @include tablet {
      padding: 0;
      &.sidebar-open {
        margin-top: 32px;
        .acc-leftwrap {
          display: block;
        }
        .acc-rightwrap {
          margin-left: 0;
          padding: 0;
          border: none;
          white-space: normal;
          width: 100%;
          visibility: hidden;
          opacity: 0;
          position: fixed;
          top: 0;
          left: 0;
          @include hw(100%);
          z-index: 999;
          background-color: $white;
          scroll-behavior: smooth;
          will-change: transform;
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overflow-x: hidden;
          &.details-open {
            visibility: visible;
            opacity: 1;
            .mydetail-wrap {
              padding-left: 0;
              padding-right: 0;
              h6,
              .detail-section,
              .action-bar {
                padding-left: 20px;
                padding-right: 20px;
              }
            }
          }
        }
      }
    }
    .acc-leftwrap {
      padding-top: 8px;
      width: 100%;
      max-width: 200px;
      @include tablet {
        padding-bottom: 8px;
        display: none;
      }
      .sidebar-links {
        color: $mid;
        cursor: pointer;
        @include tablet {
          color: $high;
          width: 100%;
          padding: 16px 12px 20px 12px;
        }
      }
      .active {
        .sidebar-links {
          color: $high;
        }
      }
      li {
        @include prefix(display, flex);
        &:not(:last-child) {
          margin-bottom: 12px;
        }
        @include tablet {
          border-bottom: 1px solid $line;
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
      @include tablet {
        max-width: 100%;
        padding-top: 0;
        h6 {
          padding-bottom: 24px;
          text-align: center;
          border-bottom: 1px solid $line;
        }
      }
    }
    .acc-rightwrap {
      padding-left: 64px;
      width: calc(100% - 200px);
      @include tablet {
        padding-left: 0;
        width: 100%;
      }
      h6 {
        margin-bottom: 20px;
        @include tablet {
          @include fonts($InterFont, $high, 16px, 500, 24px, 0.15px);
          text-transform: capitalize;
          margin-bottom: 18px;
        }
      }
      .order-items {
        .item {
          width: 100%;
          position: relative;
          background: $white;
          @include prefix(box-shadow, 0px 2px 16px 4px rgba(40, 44, 63, 0.07));
          @include prefix(border-radius, 4px);
          padding-bottom: 8px;
          &:not(:first-child) {
            margin-top: 16px;
          }
          .order-top {
            border-bottom: 1px solid $line;
            padding: 8px 16px 12px 16px;
            @include flex-between-center;
            @include ultra-mobile {
              @include flex-between-start;
            }
            .l-text {
              color: $mid;
            }
            .osline,
            .oid-date {
              padding-top: 8px;
            }
            .ototal,
            .oitem {
              padding-top: 4px;
            }
            .oid {
              @include ultra-mobile {
                text-align: right;
              }
            }
          }
          .inner-itemwrap {
            padding: 16px 16px 8px 16px;
            @include flex-between-start;
            @include ultra-mobile {
              padding: 12px 16px;
            }
            .side-img {
              img {
                max-width: 64px;
              }
            }
            .side-details {
              width: calc(100% - 80px);
              @include ultra-mobile {
                width: calc(100% - 72px);
                @include flex-start-start;
                @include prefix(flex-wrap, wrap);
                .ll {
                  @include fonts($InterFont, $high, 12px, 500, 16px, 0.5px);
                  margin-bottom: 4px;
                }
              }
              .o-attr {
                width: calc(100% - 96px);
                @include ultra-mobile {
                  width: 100%;
                }
                span {
                  position: relative;
                  color: $mid;
                  &:not(:first-child) {
                    padding-left: 12px;
                    &::before {
                      content: " ";
                      background-color: $line;
                      @include hw(4px);
                      display: inline-block;
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      left: 4px;
                    }
                  }
                }
              }
            }
          }
          .detail-action {
            @include ultra-mobile {
              text-align: right;
              padding-right: 16px;
              padding-bottom: 10px;
            }
            @include ultra-mobile-above {
              position: absolute;
              right: 16px;
              bottom: 20px;
            }
            .act-detail {
              border-bottom: 1px solid $high;
              &:hover {
                border-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}

.orderlist-wrap {
  @include tablet {
    padding: 0 20px 56px 20px;
  }
}

/*My Orders Page Skeleton Starts*/
.orderlist-wrap.skl {
  h6 {
    max-width: 25%;
  }
  li.item.skl {
    .order-top {
      .o-status, .o-total, .o-id, .o-item {
        width: calc(100% / 6);
      }
    }
    .inner-itemwrap {
      .side-img {
        width: 64px;
        height: 48px;
        span {
          min-height: 100%;
        }
      }
      .side-details {
        .product-name {
          width: 30%;
          margin-bottom: 4px;
        }
        .o-attr {
          @include prefix(display, flex);
          span {
            width: 64px;
            span {
              width: 100%;
            }
          }
        }
      }
    }
    .detail-action {
      width: 80px;
      @include ultra-mobile {
        margin-left: auto;
      }
      .act-detail {
        border-bottom: 0 !important;
      }
    }
  }
}
/*My Orders Page Skeleton Ends*/

/*My Orders Page Ends*/

/*Order Details Page Starts*/
.orderdetail-wrap {
  .account-container {
    margin: 12px auto 28px auto;
    @include tablet {
      padding: 0 23px;
    }
    @include ultra-mobile {
      padding: 0 12px;
    }
    .ord-topinfo {
      margin-top: 20px;
      .top-info {
        @include prefix (display, flex);
        @include prefix (flex-wrap, wrap);
        h6 {
          flex-basis: 100%;
        }
        p {
          display: inline;
          color: $mid;
          &.oid {
            &:before {
              content: " ";
              border-left: 1px solid $line;
              padding-left: 8px;
              margin-left: 8px;
            }
          }
        }
      }
      @include tablet {
        margin-top: 18px;
      }
      h6 {
        margin-bottom: 12px;
        @include tablet {
          @include fonts($InterFont, $high, 16px, 500, 24px, 0.15px);
          text-transform: capitalize;
          margin-bottom: 12px;
        }
      }
    }
    .ord-sections {
      @include flex-start-start;
      @include prefix(flex-wrap, wrap);
      @include tablet {
        @include prefix(flex-direction, column);
      }
      .ord-topinfo {
        flex-basis: 100%;
        width: 100%;
        margin-bottom: 20px;
      }
      .ord-details {
        width: calc(100% - 385px);
        border-right: 1px solid $line;
        @include tablet {
          width: 100%;
          border-right: 0;
        }
        .inner-itemwrap {
          @include flex-start-start;
          margin-bottom: 16px;
          @include tablet {
            @include flex-between-start;
          }
          .side-img {
            img {
              max-width: 64px;
            }
          }
          .side-details {
            padding-left: 16px;
            @include tablet {
              padding-left: 0px;
              width: calc(100% - 72px);
            }
            .o-attr {
              padding-top: 4px;
              @include prefix(display, flex);
              @include tablet {
                @include prefix(flex-wrap, wrap);
              }
              span {
                position: relative;
                color: $mid;
                &:not(:first-child) {
                  padding-left: 12px;
                  &::before {
                    content: " ";
                    background-color: $line;
                    @include hw(4px);
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 4px;
                  }
                }
              }
            }
          }
        }
        .ord-additional {
          @include flex-start-start;
          padding-top: 14px;
          @include tablet {
            @include prefix(flex-direction, column);
          }
          .intitle {
            padding-bottom: 10px;
          }
          .add-address {
            max-width: 360px;
            padding-right: 40px;
            @include tablet {
              max-width: 100%;
              padding-right: 0;
              margin-bottom: 24px;
            }
            address {
              margin: 4px 0 8px 0;
            }
          }
        }
      }
      .ord-summary {
        padding-left: 32px;
        width: calc(100% - 711px);
        @include tablet {
          width: 100%;
          padding-left: 0;
          margin-top: 24px;
        }
        .total-table {
          padding-top: 36px;
          @include tablet {
            padding-top: 20px;
          }
          .sub-col {
            @include flex-between-start;
            .stitle {
              width: 100%;
              max-width: 240px;
            }
            .svalue {
              width: calc(100% - 240px);
              text-align: right;
            }
            &:not(:last-child) {
              padding-bottom: 12px;
            }
          }
        }
      }
    }
  }
}

/*Order Details Skeleton Page Starts*/
.orderdetail-wrap.skl {
  .breadskl {
    max-width: 35%;
  }
  .ord-topinfo.skl {
    h6 {
      span {
        max-width: 40%;
      }
    }
    p {
      width: 15%;
    }
  }
  .ord-details {
    .side-img {
      width: 64px;
      height: 48px;
      span {
        min-height: 100%;
      }
    }
    .side-details {
      .o-attr {
        span {
          width: 64px;
          span {
            width: 100%;
          }
        }
      }
    }
    .add-address {
      width: 100%;
    }
    .add-payment {
      width: 100%;
      max-width: 320px;
    }
    .intitle {
      width: 60%;
    }
    .name {
      width: 30%;
    }
    address {
      min-height: 40px;
      height: 100%;
      display: block;
      span {
        min-height: inherit;
        height: inherit;
      }
    }
    .mobile {
      width: 45%;
    }
  }
  .ord-summary.skl {
    .tm {
      max-width: 65%;
      @include ultra-mobile {
        max-width: 50%;
      }
    }
    .total-table {
      .total, .maintotal {
        .stitle {
          width: 50% !important;
          @include ultra-mobile {
            width: 35% !important;
          }
        }
      }
      .delivery {
        .stitle {
          width: 65% !important;
          @include ultra-mobile {
            width: 50% !important;
          }
        }
      }
    }
  }
}
/*Order Details Skeleton Page Ends*/

/*Order Details Page Ends*/

/*My Wishlist Starts*/
.wishlist-wrap {
  @include tablet {
    padding: 0 20px;
  }
  .list-items {
    @include flex-start-start;
    @include prefix(flex-wrap, wrap);
    margin: 0 -16px;
    @include tablet {
      margin: 0 -12px;
    }
    @include ultra-mobile {
      margin: 0;
    }
    .item {
      width: calc(100% / 3);
      margin-bottom: 32px;
      padding: 0 16px;
      @include tablet {
        padding: 0 12px;
      }
      @include ultra-mobile {
        padding: 0;
        width: 100%;
      }
      .item-img {
        @include prefix(display, flex);
        @include ultra-mobile {
          a {
            width: 100%;
          }
        }
      }
      .item-info {
        padding-top: 8px;
        .price-wrapper {
          padding-top: 4px;
        }
        .wish-action {
          margin-top: 12px;
          @include flex-between-start;
          .remove {
            color: $low;
            border-bottom: 1px solid $low;
            &:hover {
              border-color: transparent;
            }
            @include ultra-mobile {
              color: $high;
              border-bottom: 1px solid $high;
            }
          }
          .atc {
            color: $high;
            border-bottom: 1px solid $high;
            &:hover {
              border-color: transparent;
            }
          }
        }
      }
    }
  }
}

/*My Wishlist Skeleton Starts*/
.wishlist-wrap.skl {
  h6 {
    max-width: 25%;
  }
  .item {
    .item-img {
      min-height: 180px;
      height: 100%;
      display: block;
      span {
        min-height: inherit;
        height: inherit;
      }
    }
    .item-name {
      max-width: 75%;
    }
    .price-wrapper.skl {
      min-height: 24px;
      height: 100%;
      @include prefix (display, flex);
      span {
        min-height: inherit;
        height: inherit;
        width: 40%;
        margin-right: 8px;
        span {
          width: 100%;
        }
      }
    }
    .wish-action.skl {
      padding-top: 4px;
      margin-top: 0;
      min-height: 24px;
      height: 100%;
      @include flex-between-center;
      span {
        min-height: inherit;
        height: inherit;
        width: 35%;
        span {
          width: 100%;
        }
      }
    }
  }
}
/*My Wishlist Skeleton Ends*/

/*My Wishlist Ends*/

/*My Details Starts*/
.mydetail-wrap {
  @include tablet {
    padding: 0 20px 32px 20px;
  }
  .detail-section {
    @include flex-start-start;
    @include ultra-mobile {
      @include prefix(flex-direction, column);
    }
    .input-groups {
      margin-bottom: 24px;
    }
    .acc-info {
      width: calc(50% - 64px);
      @include ultra-mobile {
        width: 100%;
      }
      .step-title {
        margin-bottom: 16px;
      }
      .ck-tick {
        margin-bottom: 8px;
      }
    }
    .toggle-info {
      width: 50%;
      padding-left: 64px;
      @include ultra-mobile {
        width: 100%;
        padding-left: 0;
        padding-top: 24px;
      }
      .step-title {
        margin-bottom: 16px;
        &.hide {
          display: none;
        }
      }
      .fields-set {
        .field {
          &.hide {
            display: none;
          }
        }
      }
    }
  }
  .action-bar {
    padding-top: 24px;
    .fill-btn {
      @include tablet {
        width: calc(50% - 64px) !important;
      }
      @include ultra-mobile-above {
        width: calc(50% - 64px);
      }
      @include ultra-mobile {
        width: 100% !important;
      }
    }
  }
}
/*My Details Ends*/

/*My Addresses Starts*/
.myaddress-wrap {
  @include tablet {
    padding: 0 20px;
  }
  .addr-top {
    @include flex-between-center;
    @include ultra-mobile {
      @include prefix(flex-direction, column);
      @include prefix(align-items, start);
      margin-top: 6px;
    }
    .bm.adr {
      border-bottom: 1px solid $high;
      cursor: pointer;
      &:hover {
        border-color: transparent;
      }
      @include ultra-mobile {
        margin-top: 12px;
      }
    }
  }
  .addr-list {
    margin-top: 16px;
    @include ultra-mobile {
      margin-top: 20px;
    }
    .add-item {
      @include prefix(box-shadow, 0px 2px 16px 4px rgba(40, 44, 63, 0.07));
      @include prefix(border-radius, 4px);
      padding: 16px 16px 20px;
      @include flex-between-start;
      @include ultra-mobile {
        @include prefix(flex-direction, column);
        margin-bottom: 16px;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      .addr-details {
        width: calc(100% - 120px);
        address {
          margin: 4px 0;
        }
      }
      .addr-action {
        @include ultra-mobile {
          padding-top: 12px;
        }
        a {
          border-bottom: 1px solid $high;
          &:hover {
            border-color: transparent;
          }
          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }
  }
}

/*My Addresses Skeleton Starts*/
.myaddress-wrap.skl {
  h6 {
    max-width: 25%;
  }
  .addr-top {
    span {
      width: 45%;
      border-bottom: 0 !important;
      &.bm {
        text-align: right;
        @include ultra-mobile {
          text-align: left;
        }
      }
    }
  }
  .addr-details {
    @include ultra-mobile {
      width: 100% !important;
    }
  }
  li.add-item.skl {
    .skl-name {
      max-width: 25%;
    }
    .skl-addr {
      min-height: 32px;
      height: 100%;
      display: block;
      span {
        min-height: inherit;
        height: inherit;
      }
    }
    .skl-mob {
      max-width: 20%;
    }
    .addr-action {
      width: 15%;
      margin-left: 64px;
      display: flex;
      @include ultra-mobile {
        margin-left: 0;
        width: 100%;
      }
      p {
        width: 45%;
        &:not(:last-child) {
          margin-right: 12px;
        }
        @include ultra-mobile {
          width: 25%;
        }
      }
    }
  }
}
/*My Addresses Skeleton Ends*/

/*Add New Address Popup Starts*/
.address-popup {
  @include ultra-mobile {
    background-color: $white;
  }
  .modal-dialog {
    max-width: 768px;
    @include tablet {
      max-width: 85%;
    }
    @include ultra-mobile {
      max-width: 100%;
      margin: 0;
      height: 100%;
      .modal-content {
        height: 100%;
      }
    }
    .modal-body {
      @include prefix(flex-wrap, wrap);
      @include flex-between-start;
      .select-group {
        width: calc(50% - 8px);
        margin-bottom: 24px;
        @include ultra-mobile {
          width: 100%;
        }
      }
      .input-groups {
        width: calc(50% - 8px);
        margin-bottom: 24px;
        &.full-street {
          width: 100%;
        }
        @include ultra-mobile {
          width: 100%;
        }
      }
      .action-bar {
        flex-basis: 100%;
        width: 100%;
        .fill-btn,
        .outline-btn {
          width: 160px;
          @include ultra-mobile {
            width: 100%;
          }
        }
        .outline-btn {
          margin-left: 16px;
          @include ultra-mobile {
            margin-left: 0;
            margin-top: 16px;
          }
        }
      }
    }
  }
}
/*Add New Address Popup Ends*/

/*My Addresses Ends*/

/*Newsletter Subscription Starts*/
.newsletter-wrap {
  @include tablet {
    padding: 0 20px;
  }
  .box-title {
    margin-top: 8px;
    margin-bottom: 16px;
  }
  .news-action {
    padding-top: 32px;
    .fill-btn {
      width: 100%;
      max-width: 352px;
      @include ultra-mobile {
        max-width: 100%;
      }
    }
  }
}

/*Newsletter Subscription Ends*/

/*Logout Popup Starts*/
.logout-popup {
  .logout-action {
    margin-top: 36px;
    text-align: right;
    .fill-btn {
      margin-right: 24px;
    }
  }
}
/*Logout Popup Ends*/
