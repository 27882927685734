/*Header & Footer For Checkout Starts*/
.header-main {
  .header-panel {
    &.in-checkout {
      .panel-info {
        span.lm.free-sample,
        span.ll.center-line,
        a.lm.contact {
          display: none;
        }
        > * {
          flex-basis: 100%;
        }
        .c-acc {
          border-right: 0;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
    @include ultra-mobile {
      &.in-checkout {
        display: none;
      }
    }
  }
  .header-content.in-checkout {
    .header-web {
      padding: 17px 0;
      .header-content {
        @include prefix(justify-content, center);
        .left-info,
        .right-info {
          display: none;
        }
      }
    }
  }
  .web-menu.in-checkout {
    display: none;
  }
}
.mobile-header-wrap.in-checkout {
  display: none;
  &.with-osuccess {
    display: block;
    padding: 28px 0;
    .mobile-left,
    .mobile-right {
      display: none;
    }
  }
}
.footer-main.in-checkout {
  padding-top: 0;
  border-top: 1px solid $line;
  .footer-top {
    display: none;
  }
  .footer-secondary {
    .footer-secondary {
      display: none;
      border-bottom: 0;
    }
  }
  @include ultra-mobile {
    display: none;
  }
}
/*Header & Footer For Checkout Ends*/
.checkout {
  .custom-container {
    .checkout-main {
      .checkout-left {
        @include tablet {
          max-width: 100%;
        }
        .ckout-inner {
          @include ultra-mobile {
            padding-bottom: 76px;
          }
          .alignboth-center {
            padding: 24px;
            text-align: center;
          }
          .ckout-content {
            .ckout-mid-box {
              .ckout-edit {
                @include ultra-mobile-above {
                  min-width: 120px;
                }
              }
            }
          }
          .ckout-top-box {
            @include prefix(flex-wrap, wrap);
            .ckout-head {
              span {
                &.yes {
                  background: transparent;
                  border: 0;
                  img {
                    @include hw(20px);
                    object-fit: cover;
                  }
                }
              }
            }
            .selected-address {
              flex-basis: 100%;
              padding: 12px 0 0 30px;
            }
          }
          &.yeswith-img {
            .summery-box {
              .ckout-summery {
                border: 0;
                padding-top: 0;
                .summery-txt {
                  .quantity {
                    .qty-txt {
                      p {
                        text-transform: capitalize;
                        margin-top: 4px;
                      }
                    }
                  }
                }
              }
            }
            .ckout-summery {
              .summery-img {
                img {
                  max-width: 64px;
                }
              }
            }
          }
          .s-delivmethod {
            padding-bottom: 0;
          }
          .delivery-box.selected {
            border-top: 0;
            padding: 12px 0 16px 48px;
            .selected-met {
              span.value {
                margin-left: 32px;
              }
            }
          }
          .payment-box {
            .delivery-method {
              .credit-card-content {
                button.outline-btn {
                  .loader-black-wrapper {
                    margin: 0 16px;
                  }
                }
                .braintree-dropin.braintree-loaded {
                  .braintree-placeholder {
                    display: none;
                  }
                  .braintree-sheet__header-label {
                    .braintree-sheet__text {
                      @include fonts($InterFont, $high, 16px, 500, 24px, 0.15px);
                      margin-left: 8px;
                    }
                  }
                  .braintree-card.braintree-form.braintree-sheet {
                    .braintree-form__field-group {
                      margin-bottom: 12px;
                      .braintree-form__label {
                        margin-bottom: 8px;
                      }
                      label,
                      .braintree-form__label,
                      .braintree-form__descriptor {
                        @include fonts($InterFont, $high, 12px, 500, 16px, 0.5px);
                      }
                      .braintree-form__field-error {
                        @include fonts($InterFont, $error, 12px, 400, 16px, 0.4px);
                        margin: 4px 0 0 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .checkout-price {
        span {
          @media (min-width: 768px) and (max-width: 991px) {
            padding: 32px 0 20px 0;
          }
        }
      }
    }
  }
}

.checkout {
  .custom-container {
    .checkout-main {
      .checkout-left {
        .email-input {
          max-width: 352px;
          margin-bottom: 32px;
          .forgotpass {
            margin: -8px 0 16px 0;
            text-align: right;
            a {
              border-bottom: 1px solid $high;
              &:hover {
                border-color: transparent;
              }
            }
          }
          .fill-btn {
            &[disabled] {
              padding: 0 32px;
            }
          }
        }
      }
    }
  }
}
