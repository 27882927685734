/*Web Menu Starts*/

.web-menu {
  position: relative;
  .menu-items {
    @include flex-center-center;
    .item {
      &:not(:last-child) {
        margin-right: 48px;
      }
      
    }
  }
  .item {
    padding: 8px 0;
    &.hovered {
      .dnav-item {
        a {
          border-bottom: 1px solid $high;
        }
      }
    }
    .content-section {
      display: none;
      &.active {
        display: block;
        position: absolute;
        top: 40px;
        left: 0;
        z-index: 99999;
        width: 100%;
        background: $white;
        padding: 24px 0;
      }
    }
    .menu-additional {
      a {
        display: block;
        @include fonts($InterFont, $high, 14px, 600, 20px, 1.6px);
        &.ts {
          margin-top: 8px;
        }
      }
    }
    .right-content {
      padding-left: 80px;
      @include desktop {
        padding-left: 56px;
      }
      @include tablet {
        padding-left: 40px;
      }
      li {
        width: 200px;
        margin-bottom: 12px;
        a {
          border-bottom: 1px solid transparent;
          &:hover {
            border-color: $high;
          }
        }
      }
    }
    .links-details {
      display: flex;
      .right-content {
        &:nth-child(2) {
          padding-left: 24px;
        }
      }
    }
  }
}

/*Web Menu Ends*/

/*Mobile Menu Starts*/
.app-nav {
  position: fixed;
  width: 100%;
  background-color: $white;
  top: 0px;
  height: 100vh;
  left: -9999px;
  transition: all 300ms ease;
  z-index: 999;
  &.nav--visible {
    left: 0;
    overflow: scroll;
    transition: all 300ms ease;
  }
  .sidebar-container {
    .top-section {
      padding: 16px 12px 0 20px;
      @include flex-between-center;
      .naviLevel {
        img {
          max-width: 124px;
        }
      }
      .dismiss-mob-nav {
        background: transparent;
        border: 0;
        padding: 0;
        @include flex-center-center;
      }
    }
    .mob-links {
      margin-top: 16px;
      a {
        width: 100%;
      }
      .top-part {
        .accordion-item {
          border: 0;
          border-top: 1px solid $line;
          &:first-child {
            border-top: 0;
          }
          &:last-child {
            border-bottom: 1px solid $line;
          }
          .accordion-button {
            padding-bottom: 20px;
            padding-left: 20px;
            padding-right: 12px;
            a {
              @include fonts($InterFont, $high, 14px, 500, 20px, 0.1px);
              width: auto;
            }
            &:not(.collapsed) {
              padding-bottom: 0;
              border: 0;
              background-color: $white;
              @include prefix(box-shadow, none);
            }
            &:not(.collapsed) {
              padding-bottom: 0;
              border: 0;
              background-color: $white;
              @include prefix(box-shadow, none);
            }
            &:focus {
              @include prefix(box-shadow, none);
            }
            &:after {
              content: ' ';
              @include hw(16px);
              background-image: url("../../Assets/Images/menu-down.svg");
              background-position: center;
            }
          }
          .accordion-body {
            padding: 0 12px 0 20px;
            .submenu-items {
              padding-top: 36px;
              &:last-child {
                padding-bottom: 40px;
              }
            }
            .ll {
              color: $mid;
            }
          }
        }
        .sub-lower-item {
          padding: 16px 0 20px 20px;
          border-bottom: 1px solid $line;  
        }
      }
    }
    .user-specific-link {
      margin-top: 4px;
      background-color: $surface;
      li {
        padding: 0 20px;
        margin-top: 36px;
      }
    }
  }
}
/*Mobile Menu Ends*/