/*Input Groups Starts*/
.input-groups {
  @include prefix(display, flex);
  @include prefix(flex-direction, column);
  position: relative;
  margin-bottom: 16px;
  & > label {
    display: block;
    margin-bottom: 8px;
    width: 100%;
  }
  & > input {
    display: block;
    border: 1px solid $line;
    box-sizing: border-box;
    height: 48px;
    width: 100%;
    padding: 0 16px;
    outline: none !important;
    background: $white;
    @include prefix(box-shadow, none);
    @include prefix(border-radius, 4px);
    @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
    &.error {
      border-color: $error;
    }
  }
  span.error {
    margin-top: 4px;
    @include fonts($InterFont, $error, 12px, 400, 16px, 0.4px);
  }
  input {
    &::placeholder {
      @include fonts($InterFont, $mid, 14px, 400, 20px, 0.25px);
    }
  }
  /*Password Groups Starts*/
  .pwd-wrap {
    position: relative;
    @include prefix(display, flex);
    @include prefix(flex-direction, column);
    input {
      display: block;
      border: 1px solid $line;
      box-sizing: border-box;
      height: 48px;
      width: 100%;
      padding: 0 16px;
      outline: none !important;
      background: $white;
      @include prefix(box-shadow, none);
      @include prefix(border-radius, 4px);
      @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
      &.error {
        border-color: $error;
      }
    }
    .visibilitybtn {
      position: absolute;
      border: 0;
      @include prefix(border-radius, 0px);
      right: 12px;
      top: 12px;
      padding: 0;
      &.show {
        background-image: url("../../Assets/Images/pwdshow.svg");
        background-color: transparent;
        @include hw(24px);
      }
      &.hide {
        background-image: url("../../Assets/Images/pwdhidden.svg");
        background-color: transparent;
        @include hw(24px);
      }
    }
  }
  /*Password Groups Ends*/
}
/*Input Groups Ends*/

/*Custom Button Starts*/
.outline-btn {
  height: 48px;
  @include prefix(border-radius, 4px);
  outline: none;
  border: 1px solid $line;
  padding: 0 24px;
  text-align: center;
  box-sizing: border-box;
  @include prefix(box-shadow, none);
  text-decoration: none;
  @include prefix(transition, all 300ms ease-in);
  background-color: $white;
  @include fonts($InterFont, $high, 14px, 600, 20px, 1.6px);
  text-transform: uppercase;
  &:hover {
    @include prefix(transition, all 300ms ease-in);
    cursor: pointer;
    border-color: $mid;
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.7;
    &:hover {
      border-color: $line;
    }
  }
}

.fill-btn {
  height: 48px;
  @include prefix(border-radius, 4px);
  outline: none;
  border: 0;
  padding: 0 24px;
  text-align: center;
  box-sizing: border-box;
  @include prefix(box-shadow, none);
  text-decoration: none;
  @include prefix(transition, all 300ms ease-in);
  background-color: $primary;
  @include fonts($InterFont, $white, 14px, 600, 20px, 1.6px);
  text-transform: uppercase;
  &:hover {
    @include prefix(transition, all 300ms ease-in);
    cursor: pointer;
    background-color: $button-hover;
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.7;
    &:hover {
      background-color: $high;
    }
  }
}

/*Loader Starts*/
.loader-white-wrapper,
.loader-black-wrapper {
  @include flex-center-center;
}
.loader-white {
  width: 8px;
  height: 8px;
  left: -8px;
  border-radius: 50%;
  display: block;
  position: relative;
  color: $white;
  -webkit-animation: animloaderWhite 2s linear infinite;
  animation: animloaderWhite 2s linear infinite;
}

@keyframes animloaderWhite {
  0% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  25% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  50% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 2px;
  }
  75% {
    box-shadow: 10px 0 0 2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  100% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 2px, -10px 0 0 -2px;
  }
}
@-webkit-keyframes animloaderWhite {
  0% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  25% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  50% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 2px;
  }
  75% {
    box-shadow: 10px 0 0 2px, 30px 0 0 -2px, -10px 0 0 -2px;
  }
  100% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 2px, -10px 0 0 -2px;
  }
}

.loader-black {
  width: 8px;
  height: 8px;
  left: -8px;
  border-radius: 50%;
  display: block;
  position: relative;
  color: $white;
  -webkit-animation: animloaderBlack 2s linear infinite;
  animation: animloaderBlack 2s linear infinite;
}

@keyframes animloaderBlack {
  0% {
    box-shadow: 10px 0 0 -2px $high, 30px 0 0 -2px $high, -10px 0 0 -2px $high;
  }
  25% {
    box-shadow: 10px 0 0 -2px $high, 30px 0 0 -2px $high, -10px 0 0 -2px $high;
  }
  50% {
    box-shadow: 10px 0 0 -2px $high, 30px 0 0 -2px $high, -10px 0 0 2px $high;
  }
  75% {
    box-shadow: 10px 0 0 2px $high, 30px 0 0 -2px $high, -10px 0 0 -2px $high;
  }
  100% {
    box-shadow: 10px 0 0 -2px $high, 30px 0 0 2px $high, -10px 0 0 -2px $high;
  }
}
@-webkit-keyframes animloaderBlack {
  0% {
    box-shadow: 10px 0 0 -2px $high, 30px 0 0 -2px $high, -10px 0 0 -2px $high;
  }
  25% {
    box-shadow: 10px 0 0 -2px $high, 30px 0 0 -2px $high, -10px 0 0 -2px $high;
  }
  50% {
    box-shadow: 10px 0 0 -2px $high, 30px 0 0 -2px $high, -10px 0 0 2px $high;
  }
  75% {
    box-shadow: 10px 0 0 2px $high, 30px 0 0 -2px $high, -10px 0 0 -2px $high;
  }
  100% {
    box-shadow: 10px 0 0 -2px $high, 30px 0 0 2px $high, -10px 0 0 -2px $high;
  }
}
/*Loader Ends*/

/*Custom Button Ends*/

/*Inc Dec Box Starts*/
.inc-dec-wrapper {
  @include flex-between-center;
  width: 96px;
  padding: 4px;
  border: 1px solid $line;
  @include prefix(border-radius, 4px);
  input {
    height: auto;
    padding: 0;
    border: 0;
    max-width: 32px;
    margin: 0 auto;
    text-align: center;
    @include fonts($InterFont, $high, 16px, 500, 24px, 0.15px);
  }
  button {
    border: 0;
    padding: 0;
    @include prefix(box-shadow, none);
    background-color: transparent;
    &[disabled] {
      // cursor: not-allowed;
      opacity: 0.5;
      img {
        // cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
/*Inc Dec Box Ends*/

/*Radio Button Group Starts*/
.rd {
  @include prefix(display, flex);
  align-items: center;
  input[type="radio"] {
    background-image: url("../../Assets/Images/radiobtn-default.svg");
    cursor: pointer;
    background-repeat: no-repeat;
    content: "";
    @include hw(24px);
    -webkit-appearance: initial;
    display: inline-block;
    margin: 0;
    &:hover {
      background-image: url("../../Assets/Images/radiobtn-hovered.svg");
    }
  }
  input[type="radio"]:checked {
    background-image: url("../../Assets/Images/radiobtn-checked.svg");
    cursor: pointer;
    background-repeat: no-repeat;
    content: "";
    @include hw(24px);
    -webkit-appearance: initial;
    display: inline-block;
    margin: 0;
    &:hover {
      background-image: url("../../Assets/Images/radiobtn-checked.svg");
    }
  }
  input[type="radio"]:not([disabled]):focus {
    box-shadow: none;
  }
  input[type="radio"]:hover:not([disabled]):focus {
    box-shadow: none;
  }
  label {
    cursor: pointer;
    padding-left: 8px;
  }
}
/*Radio Button Group Ends*/

/*Checkbox Button Group Starts*/
.ck {
  @include prefix(display, flex);
  align-items: center;
  input[type="checkbox"] {
    background-image: url("../../Assets/Images/fill-checkboxbtn-default.svg");
    cursor: pointer;
    background-repeat: no-repeat;
    content: "";
    @include hw(24px);
    -webkit-appearance: initial;
    display: inline-block;
    margin: 0;
    &:hover {
      background-image: url("../../Assets/Images/fill-checkboxbtn-hovered.svg");
    }
  }
  input[type="checkbox"]:checked {
    background-image: url("../../Assets/Images/fill-checkboxbtn-checked.svg");
    cursor: pointer;
    background-repeat: no-repeat;
    content: "";
    @include hw(24px);
    -webkit-appearance: initial;
    display: inline-block;
    margin: 0;
    &:hover {
      background-image: url("../../Assets/Images/fill-checkboxbtn-checked.svg");
    }
  }
  label {
    cursor: pointer;
    padding-left: 8px;
  }
}

.ck-tick {
  @include prefix(display, flex);
  align-items: center;
  input[type="checkbox"] {
    background-image: url("../../Assets/Images/tick-checkboxbtn-default.svg");
    cursor: pointer;
    background-repeat: no-repeat;
    content: "";
    @include hw(24px);
    -webkit-appearance: initial;
    display: inline-block;
    margin: 0;
    &:hover {
      background-image: url("../../Assets/Images/tick-checkboxbtn-hovered.svg");
    }
  }
  input[type="checkbox"]:checked {
    background-image: url("../../Assets/Images/tick-checkboxbtn-checked.svg");
    cursor: pointer;
    background-repeat: no-repeat;
    content: "";
    @include hw(24px);
    -webkit-appearance: initial;
    display: inline-block;
    margin: 0;
    &:hover {
      background-image: url("../../Assets/Images/tick-checkboxbtn-checked.svg");
    }
  }
  label {
    cursor: pointer;
    padding-left: 8px;
  }
}

/*Checkbox Button Group Ends*/

/*Breadcrumbs Starts*/
.cus-breadcrumb {
  padding: 8px 0;
  @include flex-start-center;
  @include prefix(flex-wrap, wrap);
  a {
    @include flex-start-center;
    position: relative;
    &::after {
      content: "/";
      margin: 0 4px;
    }
  }
  span {
    color: $mid;
    &::after {
      content: "/";
      margin: 0 4px;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

/*Breadcrumbs Ends*/

/*Select Box Starts*/
.select-group {
  @include fonts($InterFont, $mid, 14px, 400, 20px, 0.25px);
  @include prefix(display, flex);
  @include prefix(flex-direction, column);
  position: relative;
  margin-bottom: 16px;
  & > label {
    display: block;
    margin-bottom: 8px;
    width: 100%;
  }
  & > select {
    display: block;
    border: 1px solid $line;
    box-sizing: border-box;
    height: 48px;
    width: 100%;
    padding: 0 16px 0 10px;
    outline: none !important;
    background: $white;
    @include prefix(box-shadow, none);
    @include prefix(border-radius, 4px);
    @include fonts($InterFont, $high, 14px, 400, 20px, 0.25px);
    background-image: url("../../Assets/Images/selectbox-down.svg");
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position-y: center;
    background-repeat: no-repeat;
    background-position-x: 97%;
    &::-ms-expand {
      display: none;
    }
  }
}
/*Select Box Ends*/

/*Page Message Starts*/
.alert {
  padding: 12px;
  margin: 16px 0;
  border: 0;
  background-color: $surface;
  &.alert-success {
    border-left: 4px solid $success;
  }
  &.alert-danger {
    border-left: 4px solid $error;
  }
  &.alert-warning {
    border-left: 4px solid #ebb915;
  }
}
/*Page Message Ends*/
